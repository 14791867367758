import React from "react"

const TwoColumnGeneric = ({ data, children }) => {
  const section = data.primary

  return (
    <section
      id={section.section_id}
      className={`two-column-generic uk-section ${section.muted_background &&
        "uk-background-muted"}`}
    >
      <div className="uk-container uk-text-center">
        {section.section_title && <h2 className="uk-text-center">{section.section_title}</h2>}
        {section.section_subtitle && (
          <div dangerouslySetInnerHTML={{ __html: section.section_subtitle.html }} />
        )}
      </div>
      <div className="uk-container">{children}</div>
    </section>
  )
}

export default TwoColumnGeneric
